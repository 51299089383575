var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",staticStyle:{"height":"100%"}},[_c('v-container',{staticStyle:{"padding":"unset"},attrs:{"grid-list-md":"","text-xs-center":"","fluid":""}},[_c('v-layout',{attrs:{"dense":"","wrap":""}},[_vm._l((_vm.config.main_big_number_config),function(item,index){return [_c('v-flex',_vm._b({key:index},'v-flex',_vm.getCustomBreakpoint(index),false),[_c('v-list',{staticClass:"card-shadow",staticStyle:{"border-radius":"20px"},attrs:{"three-line":""}},[_c('v-list-item',{attrs:{"dark":false}},[_c('v-list-item-content',{staticStyle:{"height":"auto","justify-content":"center","align-items":"center"}},[_c('div',[_c('v-list-item-subtitle',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.title))]),_c('v-list-item-title',{staticStyle:{"font-weight":"bold","font-size":"1.4em"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticClass:"font-weight-light",staticStyle:{"color":"grey"}},[_vm._v(_vm._s(item.prefix))]),_c('span',[_vm._v(" "+_vm._s(_vm.dataProvider[item.mainNumberField] && _vm.dataProvider[item.mainNumberField].toLocaleString( 'en-IN', { maximumFractionDigits: 2 } )))]),_c('span',{staticClass:"font-weight-light",staticStyle:{"color":"grey"}},[_vm._v(_vm._s(item.sufix))])])]),(_vm.dataProvider[item.smallNumberField])?_c('div',[_c('v-list-item-subtitle',{staticStyle:{"display":"block","text-align":"center"},style:({
                        color: _vm.setColor(
                          _vm.dataProvider[item.smallNumberField],
                          index
                        )
                      })},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.labelText1 || item.labelText),expression:"item.labelText1 || item.labelText"}],style:({
                          color: _vm.setColor(
                            _vm.dataProvider[item.smallNumberField],
                            index
                          )
                        }),attrs:{"small":""}},[_vm._v(_vm._s(_vm.remarkToneFeild(item) == true ? 'mdi-arrow-up' : 'mdi-arrow-down'))]),_c('span',[_vm._v(_vm._s(_vm.dataProvider[item.smallNumberField])+_vm._s(item.smallNumberSufix))])],1)],1):_vm._e()],1)])],1)],1)],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }