export function makeDataProvider(dataSource) {
    const maxLen = 5
    let dataProvider = []
    let columns = dataSource.headers
    for (let i = 0; i < maxLen; i++) {
        let obj = {}
        columns.forEach((el, idx) => {
            switch (el.type) {
                case 'Text':
                    obj[el.header] = el.header + '-' + i
                    break
                case 'Number':
                    obj[el.header] = (i + idx + (el.id || 1)) * 10
                    break
                default:
                    break
            }
        })
        dataProvider.push(obj)
    }
    console.log(`[dataProvider]: `, dataProvider);
    return dataProvider
}

export function mapHeaderAndData(data, header) {
    let res = []
    header.forEach((el) => {
        data.forEach((row, idx) => {
            if (!row.hasOwnProperty(el.header)) {
                row[el.header] =
                    el.type == 'Number'
                        ? Math.round(Math.random() * 100)
                        : 'CAT ' + idx
            } else {
            }
        })
    })
    return data
}


export function tableCellsPallete() {
    return [
        '#BF360C', '#D84315', '#E64A19', '#E65100', '#EF6C00', '#F57C00', '#F57F17', // red to orange , white text
        //[behind 7] : 35%  white bg till here| after this black. [13 ahead] 
        // '#FFAB00', '#FFC400', '#FFD600', '#FFEA00', // orange to yellow :: dark
        '#FFAB00', '#FFC400', '#d6921e', '#cf8506', // orange to yellow :: dark --> (overwrite yellow with orange)
        '#9E9D24', '#689F38', '#558B2F', '#2E7D32',  // yellow to green :: dark
        '#00897B', '#00ACC1', '#0097A7', "#0288D1", "#0277BD" // teal to blue  :: dark
    ]
}