<template>
  <div class="main" style="height: 100%">
    <!-- <div style=""> -->
    <!-- <div> -->
    <!-- <v-card  elevation="3" style="border-radius: 15px !important;"> -->
    <v-container grid-list-md text-xs-center fluid style="padding: unset">
      <v-layout dense wrap>
        <template v-for="(item, index) in config.main_big_number_config">
          <v-flex v-bind="getCustomBreakpoint(index)" :key="index">
            <v-list style="border-radius: 20px" class="card-shadow" three-line>
              <v-list-item :dark="false">
                <v-list-item-content
                  style="
                    height: auto;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div>
                    <v-list-item-subtitle style="text-align: center">{{
                      item.title
                    }}</v-list-item-subtitle>
                    <v-list-item-title
                      style="font-weight: bold; font-size: 1.4em"
                    >
                      <div style="text-align: center">
                        <span class="font-weight-light" style="color: grey">{{
                          item.prefix
                        }}</span>
                        <span>
                          {{
                            dataProvider[item.mainNumberField] &&
                            dataProvider[item.mainNumberField].toLocaleString(
                              'en-IN',
                              {
                                maximumFractionDigits: 2
                              }
                            )
                          }}</span
                        >
                        <span class="font-weight-light" style="color: grey">{{
                          item.sufix
                        }}</span>
                      </div>
                    </v-list-item-title>
                    <div v-if="dataProvider[item.smallNumberField]">
                      <v-list-item-subtitle
                        style="display: block; text-align: center"
                        :style="{
                          color: setColor(
                            dataProvider[item.smallNumberField],
                            index
                          )
                        }"
                      >
                        <v-icon
                          v-show="item.labelText1 || item.labelText"
                          small
                          :style="{
                            color: setColor(
                              dataProvider[item.smallNumberField],
                              index
                            )
                          }"
                          >{{
                            remarkToneFeild(item) == true
                              ? 'mdi-arrow-up'
                              : 'mdi-arrow-down'
                          }}</v-icon
                        >
                        <span
                          >{{ dataProvider[item.smallNumberField]
                          }}{{ item.smallNumberSufix }}</span
                        >
                      </v-list-item-subtitle>
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
    <!--   </v-card> -->
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import { makeDataProvider, tableCellsPallete } from '../common.js'
export default {
  props: ['configuration', 'dataSource'],
  data: () => ({
    dataProvider: {},
    config: {}
  }),
  created: function () {
    console.log('========plain==========', this)
    this.config = JSON.parse(JSON.stringify(this.configuration || {}))
    if (
      this.dataSource &&
      this.dataSource.headers &&
      this.dataSource.headers.length
    ) {
      if (this.dataSource.rows.length) {
        this.dataProvider = this.dataSource.rows[0]
      } else {
        this.dataProvider = makeDataProvider(this.dataSource)[0]
      }
    }
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name
    }
  },
  methods: {
    remarkToneFeild(item) {
      console.log('Logged Output: remarkToneFeild -> item', item)
      let remarkNumberCheck = Math.sign(item.remarkToneFeild)
      if (
        (item.colorScale == 'bad_good' && remarkNumberCheck >= 0) ||
        (item.colorScale == 'good_bad' && remarkNumberCheck < 0)
      ) {
        return true
      } else {
        return false
      }
      // })
      // console.log("Logged Output: remarkToneFeild -> remark", remark)
      // return remark[0];
    },
    getCustomBreakpoint(index) {
      let defaultBreakpoint = {
        xs6: true,
        md4: true
      }
      index++
      let length = this.config.main_big_number_config.length
      if (['xs', 'sm'].includes(this.breakpoint)) {
        let lastElementLength = length % 2
        if (lastElementLength === 1 && index === length) {
          return {
            xs12: true
          }
        } else {
          return defaultBreakpoint
        }
      } else {
        let lastElementLength = length % 3
        if (lastElementLength === 1 && index === length) {
          return {
            xs12: true
          }
        } else if (
          lastElementLength === 2 &&
          [length, length - 1].includes(index)
        ) {
          return {
            xs6: true
          }
        } else {
          return defaultBreakpoint
        }
      }
    },
    setColor(value, index) {
      let range = this.config.main_big_number_config[index].range
      let colorScale = this.config.main_big_number_config[index].colorScale
      let N = (range && range.toString().split(',').map(Number)) || []
      let colors = [tableCellsPallete()[0]]
      let colorPelletsLength = tableCellsPallete().length
      let jumper = Math.floor(colorPelletsLength / (N.length + 1))
      let jumperSum = 0
      for (let i = 0; i < N.length; i++) {
        jumperSum += jumper
        colors.push(tableCellsPallete()[jumperSum])
        // console.log("jumper sum", jumperSum);
      }
      // console.log('-----------------------');
      // console.log('N :>> ', N);
      // console.log('colors :>> ', colors);
      // console.log("value", value);
      // console.log('-----------------------');
      if (colorScale === 'good_bad') {
        colors = colors.reverse()
      }
      if (value < N[0]) {
        return colors[0]
      } else if (value > N[N.length - 1]) {
        return colors[colors.length - 1]
      } else {
        console.log('colors value', colors[1])
        for (let i = 0; i <= N.length; i++) {
          if (value <= N[i]) return colors[i]
        }
      }
    }
  }
}
</script>

<style scoped>
.card-shadow:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.6s;
}
.v-list {
  display: block;
  /* padding: 8px 0; */
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}
</style>